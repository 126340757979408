<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Get in touch</h2>
                <p><a href="mailto:office@kd.net.nz?Subject=Website%20enquiry">office@kd.net.nz</a><br/>
                    Phone: <a href="tel:043852046">(04) 385 2046</a><br/>
                    Fax: (04) 385 2047</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h2>Address</h2>
                <p>Level 3, 173-175 Victoria Street<br/>
                    Te Aro<br/>
                    Wellington</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h2>Postal address</h2>
                <p>PO Box 887<br/>
                    Wellington, 6040 </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.776162634876!2d174.77362992309392!3d-41.29197579236956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d38afd0a69a27cd%3A0x3f6a0370ccb42b9b!2sKing+%26+Dawson+Architects+%26+Engineers!5e0!3m2!1sen!2snz!4v1483662398690" width="100%" height="600" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>