<template>
    <div class="container">
        <div class="row">
            <div class="proj-container col-md-4 col-sm-6" v-for="(project, index) in projects" :key="index" v-once>
                <div class="proj-contents" :style="{'background-image': `url(${project.thumbnail})`}">
                    <router-link :to="project.path">{{project.name}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {projects} from '../router';
export default {
    setup() {
        return {projects};
    }
}
</script>