<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>History</h2>
                <p class="introduction">King &amp; Dawson Architects &amp; Engineers Ltd was established in 1906, when J M Dawson set up his architectural firm in Wellington. Since then, many of the buildings first drafted on our firms drawing boards have become part of NZ's history. Notable works in Wellington include: Freyberg Pool, St Francis de Sales Church, Colonial Motor Company Assembly Factory in Taranaki Street (CMC Building), the Bell Tower at St Paul’s Cathedral and the Hope Gibbons Building.</p>
                <h2>Company Directors</h2>
                <p>Our firm is headed by two Directors - John Wilson and Achilles Botes, who are actively involved in the day to day running of the practice.</p>
                <h3>John Wilson - B.E.Hons, MIPENZ, CPeng</h3>
                <p>John holds a Bachelor of Engineering - Civil (with Honors) Degree. During his 34 years with King &amp; Dawson, John has brought his engineering expertise to many projects.</p>
                <p>He began working as an engineer in 1971 and is an efficient designer of steel structures, especially low rise industrial constructions such as cool stores. He also has wide experience in the analysis and strengthening of elderly structures, including bridges and earthquake risk buildings. An "eye" for economic simplicity and innovation is a feature of John's work.</p>
                <p>John has extensive experience in reporting on the causes and repair of various structural failures, risks, or damage for insurance brokers and others. He has been responsible for the project design and implementation for a wide range of buildings and contracts.</p>
                <p>John is a member of the Institute of Professional Engineers, and the New Zealand National Society for Earthquake Engineering, and a Licensed Building Practitioner.</p>
                <h3>Achilles Botes - BBSC, BArch (Hons), NZIA</h3>
                <p>Achilles holds a Bachelor of Building Science and a Bachelor of Architecture (with Honors) Degrees. Since graduating, Achilles has over 30 years experience in a wide variety of projects, and is fully conversant with all facets of project delivery. Achilles' strengths lie in problem solving, design and contract documentation and contract administration.</p>
                <p>He has developed an understanding of material technology and the importance of sound detailing and documentation through involvement in numerous leaky buildings.</p>
                <p>Achilles is a Registered Architect, member of the New Zealand Institute of Architects and a Licensed Building Practitioner.</p>
            </div>
        </div>
    </div>
</template>
